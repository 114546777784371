import React from "react"
import Layout from "@components/layout"
import { graphql } from "gatsby"
import parse from "html-react-parser"
import { GatsbyImage } from "gatsby-plugin-image"

// Import SVG
import icon_t_SVG1 from '@images/top_t_icon1.svg';
// import icon_post_SVG1 from '@images/post_icon1.svg';
// import icon_post_SVG2 from '@images/post_icon2.svg';
import icon_post_SVG3 from '@images/post_icon3.svg';
// import icon_sns_SVG1 from '@images/sns_icon1.svg';
// import icon_sns_SVG2 from '@images/sns_icon2.svg';
// import blogProf from '@images/blog_pro_img.png';

// WP CSS
import "@css/@wordpress/block-library/build-style/style.css"
import "@css/@wordpress/block-library/build-style/theme.css"

// コンポーネント呼び出し
import { SearchCategList } from "@components/search-categList.js"; // カテゴリ検索機能
import { RelatedContents } from "@components/related-article.js"; // 関連記事取得・形成
import { FixedFoot } from "@components/footer";
import { WordSearch } from "@components/word-search.js";
import { ProfileContents } from "@components/profile.js";
import { SeoContents } from "@components/seo-data.js" // Seo情報設定（Title,description以外）
import { FaqGraphqlArray } from "@components/graphql-data-faq.js"; // 記事情報取得・形成

const FaqArchive = ({data, pageContext}) => {
  const indexUrl = '/';
  const myUrl = 'faq/'
  const name = pageContext.name; // 該当タグ名
  const slug = pageContext.slug; // 該当スラッグ名
  const myPageNum = pageContext.page;
  
  // graphql-data-post.jsから記事データ呼び出し
  const setNum = 6; // 一覧の表示数
  const allPostData = FaqGraphqlArray(name, 'allmix');

  // 本文設定（各カテゴリ、blog-archive.jsonで設定）
  // const commonTitle = blogText[ "common-title" ];
  // const postTitle = blogText[ pageContext.slug + "-title" ];
  // const postText1 = blogText[ pageContext.slug + "-txt1" ];
  const commonTitle = "共通タイトル";
  const postTitle = name;
  const postText1 = "テキスト";
  
  // Seo情報設定（Title,description以外）
  const pathName = 'tags';
  const seoTag = SeoContents({ Path:pathName, Title:name });


  // 記事一覧生成設定
  let setArray, startNum, endNum;
  setArray = allPostData.checkCateg; // 該当カテゴリ配列情報
  let resultArray =[];
  startNum = (myPageNum - 1) * setNum; // 一覧の先頭番号
  endNum = myPageNum * setNum; // 一覧の最終番号
  endNum = (endNum > setArray.length) ? setArray.length : endNum; // 6個に足りていない場合の処理
  for (let i = startNum; i < endNum; i++){
    resultArray.push(setArray[i])
  }
  console.log(resultArray)
  const pickupList = resultArray.map((result) => {
    let shortName; // タイトル文字数制限設定
    const len = 35; // 制限文字数
    if ( result.Title.length > len ) {
      shortName = result.Title.substr( 0, len) + ' ...'; // 省略
    } else {
      shortName = result.Title;
    }
    let shortNameEx; // Description文字数制限設定
    const lenEx = 60; // 制限文字数
    if ( result.Description.length > lenEx ) {
      shortNameEx = result.Description.substr( 0, lenEx) + ' ...'; // 省略
    } else {
      shortNameEx = result.Description;
    }
    return  <>
              <div className="" key={result.Uri} >
                <a href={indexUrl + myUrl + result.Slug + "/"} itemProp="url" className="Campaign w-[100%] h-[100%]">
                  {/* タイトル部分 */}
                  <p className="CampP md:text-[15px] text-[15px]  mb-4 py-2 text-left " >{shortName}</p> 
                  </a>
              </div>
            </> ;
    });

  // ページネーション設定
  let previous = myPageNum - 1;
  let previousUrl = (previous === 1) ? indexUrl + myUrl + pageContext.slug + "/" : ((previous < 1) ? "" : indexUrl + myUrl + pageContext.slug + "/" + previous + "/");
  let current = myPageNum;
  let currentUrl = (current === 1) ? indexUrl + myUrl + pageContext.slug + "/" : ((current < 1) ? "" : indexUrl + myUrl + pageContext.slug + "/" + current + "/");
  let next = myPageNum + 1;
  let nextUrl = (next === 1) ? indexUrl + myUrl + pageContext.slug + "/" : ((next > pageContext.allPages) ? "" : indexUrl + myUrl + pageContext.slug + "/" + next + "/");

  const fixedItem = FixedFoot( 'nomal', '' ); // 固定フッター

    return (
      <>
        <Layout>
          {seoTag}
          <div className="bg-[#F6F6F6] md:pb-[45px] pb-2">
            <div className="px-5 max-w-xl mx-auto md:max-w-5xl ">
              <div className="pb-4 pt-0 md:pt-10  pt-0  max-w-[800px] ">
                <h2 className="md:text-5xl text-3xl font-black mb-5 text-left pt-5 !leading-[1.2]">タイトル</h2>
                <h3 className="md:text-3xl text-2xl font-black  text-left  text-[#1F55A6]">プロップファームを利用する際に必要になる<br></br>注意点や基本的知識などを掲載しています。</h3>
              </div>
            </div>          
          </div>
          
          {/* カテゴリ本文 */}
          <div className="md:max-w-5xl max-w-xl mx-auto pt-5 px-5 pb-5"> 
            <div>
              <h3 className="md:text-3xl text-2xl font-black mb-10 text-left  text-[#1F55A6] mt-[70px] ">{ postTitle }</h3>
              <p className="mb-[30px] max-w-[300px] md:max-w-[960px] overflow-hidden">{postText1}</p>
            </div>
          </div>

          {/* 記事一覧 */}
          <div className="mx-auto max-w-xl md:max-w-5xl md:justify-start px-5">
            {pickupList}
          </div>

          {/* ページネーション */}
          <div className="flex py-10 text-[11px] text-sm bg-[#F6F6F6]">
            <div className="text-center w-1/2">
              {previousUrl && <a href={previousUrl}>＜ PREV</a>}
            </div>
            <div className="text-center w-1/2">
              {nextUrl && <a href={nextUrl}>NEXT ＞</a>}
            </div> 
          </div>

          {/* 固定フッター */}
          {fixedItem}
        </Layout>
      </>
    )
  }
export default FaqArchive